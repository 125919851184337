/* CartItem Component Style
----------------------------------- */

.cart-item {
  background: #fff;
  padding: 20px;
  margin-bottom: 20px;
}

.cart-item-header {
  border-bottom: 1px solid #ddd;
}

.cart-item-title {
  margin: 0 0 12px;
  font-size: 21px;
}

.cart-item-info {
  display: flex;
  align-items: baseline;
  justify-content: space-between;
}

.cart-item-btn {
  border: 0;
  padding: 5px;
  margin-left: -5px;
  color: #007185;
  cursor: pointer;
  background: transparent;
}

.cart-item-btn:hover {
  color: #c7511f;
  text-decoration: underline;
}

.cart-price {
  display: none;
}

.cart-subtotal {
  text-align: right;
  padding-bottom: 10px;
}

.loader {
  position: absolute;
  top: 0px;
  right: 0px;
  width: 100%;
  height: 100%;
  background-color: #eceaea;
  background-image: url("../../assets/img/loading.gif");
  background-size: 50px;
  background-repeat: no-repeat;
  background-position: center;
  z-index: 10000000;
  opacity: 0.4;
  filter: alpha(opacity=40);
}
/* Media Queries
  -------------------------------------- */

@media (min-width: 768px) {
  .cart-price {
    display: block;
  }
}

/* CartProduct Component Style
----------------------------------- */

.cart-product > div {
  border-bottom: 1px solid #ddd;
  padding: 20px 0;
}

.cart-product-link {
  display: flex;
  justify-content: center;
}

.cart-product-details {
  display: flex;
  flex-direction: column;
}

.title-wrap p {
  margin: 16px 0;
}

.title-wrap a {
  color: #111;
}

.stock-status,
.cart-product-btn-wrap {
  padding-top: 20px;
}

.stock-status {
  color: #007600;
}

.cart-product-btn-wrap {
  overflow: auto;
}

.cart-product-btn-wrap button {
  border: 0;
  font-size: 12px;
  color: #007185;
  padding: 5px 10px;
  cursor: pointer;
  background: transparent;
}

.cart-product-btn-wrap button:hover {
  color: #c7511f;
  text-decoration: underline;
}

.cart-product-price {
  justify-self: end;
}

/* Media Queries
-------------------------------------- */

@media (min-width: 576px) {
  .cart-product > div {
    display: grid;
    grid-template-columns: 1fr 4fr;
    column-gap: 12px;
  }

  .cart-product-link {
    flex-direction: column;
  }

  /* .cart-product-img {
    max-height: "250px";
    max-width: "250px";
    background-size: "contain";
    margin-left: "auto";
    margin-right: "auto";
  } */
}

@media (min-width: 768px) {
  .cart-product > div {
    display: grid;
    grid-template-columns: 1fr 4fr;
    column-gap: 12px;
  }

  .cart-product-details {
    margin-top: 0;
  }

  .cart-product-link {
    justify-content: flex-start;
  }

  .title-wrap {
    display: grid;
    grid-template-columns: 4fr 0.6fr;
  }

  .title-wrap p {
    margin: 0;
  }

  .cart-product-btn-wrap button:nth-child(2n + 2) {
    border-right: 1px solid #ddd;
    border-left: 1px solid #ddd;
  }
}

/*************** ProgressBar ******************/

.timer-wrapper {
  display: flex;
  justify-content: center;
}

.timer {
  font-family: "Montserrat";
  display: flex;
  flex-direction: column;
  align-items: center;
}

.text {
  color: #aaa;
}

.value {
  font-size: 20px;
}

.info {
  max-width: 360px;
  margin: 40px auto 0;
  text-align: center;
  font-size: 16px;
}

/***************** End **********************/
