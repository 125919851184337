
.coinsuites-modal *, .coinsuites-modal *::after, .coinsuites-modal *::before {
  box-sizing: border-box;
}
.coinsuitespaybtn {
  cursor: pointer;
}
.information-block {
  background-color: #f2dede;
  text-align: center;
  border: 1px solid #ebccd1;

  border-radius: 4px;

  color: #b94a48;

  font-size: 14px;

  margin: 0;

  margin-bottom: 0px;
  padding: 20px;
  margin-bottom: 20px;
  margin-left: 15px;
  margin-right: 15px;
}


.coin {
  width: 20px;
}

.coinselect {
  width: 40px;
}
.openwallet {
  padding: 6px 11px !important;
  background-color: #fff;
  font-size: 16px;
  border-radius: 5px;
  color: #03317e;
  border: solid 1px #03317e;
  margin-top: 10px;
  text-align: center;
  cursor: pointer;
  text-decoration: none;
  display: inline-block;
}

.coinsuitesbtnreload {
  padding: 6px 11px !important;
  background-color: #03317e;
  font-size: 16px;
  border-radius: 5px;
  color: #fff;
  text-align: center;
  padding: 10px;
  cursor: pointer;
  margin-top: 20px;
}
.coinsuites-widget {
  background: #fff;
}

.selectBtn {
  max-width: 100%;
  border-radius: 5px;
  padding: 10px 20px;
  font-size: 1.2em;
  text-align: left;
  margin: 5px;
  cursor: pointer;
  display: flex;
  align-items: center;
  border: 1px solid #dcdcdc;
  width: 100%;
}
.selectBtn img {
  margin-right: 10px;
}
.disclaimer {
  font-size: 12px;
  padding-top: 10px;
  padding-bottom: 10px;
}
.main-content {
  margin-top: 40px;
  margin-bottom: 40px;
}
.invoiceHolder {
  width: 100%;
  min-height: 400px;
  padding: 20px;
  background-color: #fff;
  border-radius: 5px;
  border: solid 1px #ccc;
  margin-top: 20px;
}
.coinsuiteslogo {
  width: 125px;
  margin-top: 5px;
}

.paynow {
  width: 180px;
  cursor: pointer;
  max-width: 100%;
}
.paynow img {
  width: 100%;
}
.coinsuitesModalTop {
  height: 50px;
  background-color: #03317e;
  border-bottom: solid 1px #03317e;
  text-align: center;
}
.coinsuitesbtn {
  padding: 6px 11px !important;
  background-color: #03317e;
  font-size: 16px;
  border-radius: 5px;
  color: #fff;
  text-align: center;
  padding: 10px;
  cursor: pointer;
  border: 0
}

.section {
  padding-top: 10px;
  padding-bottom: 10px;
}
.spinner {
  width: 50px;
  height: 50px;
  -webkit-animation-name: spin;
  -webkit-animation-duration: 4000ms;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;
  -moz-animation-name: spin;
  -moz-animation-duration: 4000ms;
  -moz-animation-iteration-count: infinite;
  -moz-animation-timing-function: linear;
  -ms-animation-name: spin;
  -ms-animation-duration: 4000ms;
  -ms-animation-iteration-count: infinite;
  -ms-animation-timing-function: linear;

  animation-name: spin;
  animation-duration: 4000ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

@-ms-keyframes spin {
  from {
    -ms-transform: rotate(0deg);
  }
  to {
    -ms-transform: rotate(360deg);
  }
}
@-moz-keyframes spin {
  from {
    -moz-transform: rotate(0deg);
  }
  to {
    -moz-transform: rotate(360deg);
  }
}
@-webkit-keyframes spin {
  from {
    -webkit-transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.seperator {
  border-bottom: solid 1px #03317e;
  position: relative;
  margin-top: 10px;
  margin-bottom: 10px;
}
.or {
  width: 25px;
  z-index: 10;
  height: 25px;

  border-radius: 50%;

  color: #03317e;

  background-color: #fff;

  font-size: 10px;

  padding: 5px;

  display: inline-block;

  position: absolute;

  top: -10px;

  left: 50%;

  right: 50%;

  margin-left: -12.5px;
}

.overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 99999;
  background-color: rgba(0, 0, 0, 0.1);
}
.spinner {
  color: #ccc;
}

.coinsuitesModalInner {
  text-align: center;
  height: auto;
  margin: 0;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  max-width: 96%;
  max-height: 100%;
  overflow: auto;
  position: absolute;
  background-color: #fff;
  border-radius: 5px;
  font-size: 14px;
  width: 350px;

  font-family: 'Open Sans', sans-serif;
}
.coinsuitesModalContent {
  margin-left: 20px;
  margin-right: 20px;
}
.address {
  font-size: 12px;
  margin-top: 5px;
  margin-bottom: 5px;
  padding: 5px;
  border: solid 1px #ccc;
  display: inline-block;
}

.loaderHolder {
  padding: 30px 15px;
  min-height: calc(300px);
  display: block;
  color: #ccc;
}
.loader {
  font-size: 25px;
  margin-top: 115px;
}
.center {
  text-align: center;
}

.expired {
  background-color: #fff;
  padding: 10px 6px;
  color: crimson;
  display: block;
  margin-top: 10px;
  border: solid 1px #f8bb86;
}

.coinsuitesAction {
  border: 0;
  padding: 0;
  margin: 0;
  background: transparent;
  box-shadow: none;
  cursor: pointer;
  outline: none;
  position: relative;
  display: inline-block;
}